var config = {
    env: 'prod',
    //airdrop配置
    // distributor: '95Bn5WDdccmcX4MuF1mZRG4vg1xJiEm2vdZEgGdAzaPq',
    // tokenAccount: '5JS7QrZtgPXwMfCfMbN5jhe5maQoJsUZcdCRDJkrQfZx',
    // webUrl:'https://app.effectiveai.xyz'
    //生产正式环境配置
    distributor: '3SGVb7RTAtZCJmYC3YfEnWWYeV7V3TaaaN8uX47wmEWr',
    tokenAccount: '26iAWZAXybtPfqZ4vj5fWt96ZoLvZYJx1RF2Dx2Y3BgL',
    webUrl: 'https://app.effectiveai.xyz'
};
export default config;
