import React from 'react';
import './ConnectWalletButton.less';
var ConnectWalletButton = function (_a) {
    var children = _a.children, gradient = _a.gradient, disabled = _a.disabled, onClick = _a.onClick;
    if (disabled) {
        return (React.createElement("button", { disabled: true, className: 'button-disabled' },
            React.createElement("span", null, children)));
    }
    if (gradient) {
        return (React.createElement("button", { className: 'button-gradient', onClick: onClick },
            React.createElement("span", null, children)));
    }
    return React.createElement("button", { className: "button-primary", onClick: onClick },
        React.createElement("span", null, children));
};
export default ConnectWalletButton;
