var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as anchor from '@project-serum/anchor';
import { PublicKey } from '@solana/web3.js';
import { AccountLayout, MintLayout, Token, TOKEN_PROGRAM_ID, } from '@solana/spl-token';
import BN from 'bn.js';
import { CANDY_MACHINE_ID, SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID, TOKEN_METADATA_PROGRAM_ID, } from './ids';
export var getMintInfo = function (connection, mint) { return __awaiter(void 0, void 0, void 0, function () {
    var mintKey, mintAccount, mintOwner, mintInfo;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                try {
                    mintKey = new PublicKey(mint);
                }
                catch (err) {
                    throw new Error("Invalid mint key ".concat(err));
                }
                return [4 /*yield*/, connection.getAccountInfo(mintKey)];
            case 1:
                mintAccount = _a.sent();
                if (mintAccount === null) {
                    throw new Error("Could not fetch mint");
                }
                if (!mintAccount.owner.equals(TOKEN_PROGRAM_ID)) {
                    mintOwner = mintAccount.owner.toBase58();
                    throw new Error("Invalid mint owner ".concat(mintOwner));
                }
                if (mintAccount.data.length !== MintLayout.span) {
                    throw new Error("Invalid mint size ".concat(mintAccount.data.length));
                }
                mintInfo = MintLayout.decode(Buffer.from(mintAccount.data));
                return [2 /*return*/, {
                        key: mintKey,
                        info: mintInfo,
                    }];
        }
    });
}); };
export var getATA = function (walletKey, mintKey) {
    return Token.getAssociatedTokenAddress(SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID, TOKEN_PROGRAM_ID, mintKey, walletKey, true);
};
export var getATAChecked = function (walletKey, connection, mintKey, totalClaim) { return __awaiter(void 0, void 0, void 0, function () {
    var ataKey, ataAccount, ataInfo;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getATA(walletKey, mintKey)];
            case 1:
                ataKey = _a.sent();
                return [4 /*yield*/, connection.getAccountInfo(ataKey)];
            case 2:
                ataAccount = _a.sent();
                if (ataAccount === null) {
                    throw new Error("Failed to fetch associated token account for ".concat(mintKey.toBase58()));
                }
                if (ataAccount.data.length !== AccountLayout.span) {
                    throw new Error("Invalid token account size ".concat(ataAccount.data.length));
                }
                ataInfo = AccountLayout.decode(Buffer.from(ataAccount.data));
                if (new BN(ataInfo.amount, 8, 'le').lt(totalClaim)) {
                    // TODO: decimals?
                    throw new Error("Associated token account does not have enough tokens. Expected ".concat(totalClaim));
                }
                return [2 /*return*/, ataKey];
        }
    });
}); };
export var fetchCoder = function (address, connection) { return __awaiter(void 0, void 0, void 0, function () {
    var idl;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, anchor.Program.fetchIdl(address, {
                    connection: connection,
                })];
            case 1:
                idl = _a.sent();
                if (!idl)
                    return [2 /*return*/, null];
                return [2 /*return*/, new anchor.Coder(idl)];
        }
    });
}); };
export var getCandyConfig = function (connection, config) { return __awaiter(void 0, void 0, void 0, function () {
    var configKey, configAccount;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                try {
                    configKey = new PublicKey(config);
                }
                catch (err) {
                    throw new Error("Invalid config key ".concat(err));
                }
                return [4 /*yield*/, connection.getAccountInfo(configKey)];
            case 1:
                configAccount = _a.sent();
                if (configAccount === null) {
                    throw new Error("Could not fetch config");
                }
                if (!configAccount.owner.equals(CANDY_MACHINE_ID)) {
                    throw new Error("Invalid config owner ".concat(configAccount.owner.toBase58()));
                }
                return [2 /*return*/, configKey];
        }
    });
}); };
export var getCandyMachineAddress = function (config, uuid) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, PublicKey.findProgramAddress([Buffer.from('candy_machine'), config.toBuffer(), Buffer.from(uuid)], CANDY_MACHINE_ID)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getCandyMachine = function (connection, candyMachineKey) { return __awaiter(void 0, void 0, void 0, function () {
    var candyMachineCoder, candyMachineAccount;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchCoder(CANDY_MACHINE_ID, connection)];
            case 1:
                candyMachineCoder = _a.sent();
                if (candyMachineCoder === null) {
                    throw new Error("Could not fetch candy machine IDL");
                }
                return [4 /*yield*/, connection.getAccountInfo(candyMachineKey)];
            case 2:
                candyMachineAccount = _a.sent();
                if (candyMachineAccount === null) {
                    throw new Error("Could not fetch candy machine");
                }
                return [2 /*return*/, candyMachineCoder.accounts.decode('CandyMachine', candyMachineAccount.data)];
        }
    });
}); };
export var getMetadata = function (mint) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, PublicKey.findProgramAddress([
                    Buffer.from('metadata'),
                    TOKEN_METADATA_PROGRAM_ID.toBuffer(),
                    mint.toBuffer(),
                ], TOKEN_METADATA_PROGRAM_ID)];
            case 1: return [2 /*return*/, (_a.sent())[0]];
        }
    });
}); };
export var getEdition = function (mint) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, PublicKey.findProgramAddress([
                    Buffer.from('metadata'),
                    TOKEN_METADATA_PROGRAM_ID.toBuffer(),
                    mint.toBuffer(),
                    Buffer.from('edition'),
                ], TOKEN_METADATA_PROGRAM_ID)];
            case 1: return [2 /*return*/, (_a.sent())[0]];
        }
    });
}); };
export var getEditionMarkerPda = function (mint, edition) { return __awaiter(void 0, void 0, void 0, function () {
    var editionPageNumber;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                editionPageNumber = edition.div(new BN(248)).toNumber();
                return [4 /*yield*/, PublicKey.findProgramAddress([
                        Buffer.from('metadata'),
                        TOKEN_METADATA_PROGRAM_ID.toBuffer(),
                        mint.toBuffer(),
                        Buffer.from('edition'),
                        Buffer.from(String(editionPageNumber)),
                    ], TOKEN_METADATA_PROGRAM_ID)];
            case 1: return [2 /*return*/, (_a.sent())[0]];
        }
    });
}); };
