var _a;
var _b, _c;
import ConfigDev from './ConfigDev';
import ConfigTest from './ConfigTest';
import ConfigProd from './ConfigProd';
var configs = (_a = {},
    _a[ConfigDev.env] = ConfigDev,
    _a[ConfigProd.env] = ConfigProd,
    _a[ConfigTest.env] = ConfigTest,
    _a);
export var config = (_c = configs[(_b = process.env.APP_ENV) !== null && _b !== void 0 ? _b : ConfigDev.env]) !== null && _c !== void 0 ? _c : ConfigDev;
