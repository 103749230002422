import React from 'react';
import { notification } from 'antd';
// import Link from '../components/Link';
export function notify(_a) {
    var _b = _a.message, message = _b === void 0 ? '' : _b, _c = _a.description, description = _c === void 0 ? undefined : _c, _d = _a.txid, txid = _d === void 0 ? '' : _d, _e = _a.type, type = _e === void 0 ? 'info' : _e, _f = _a.placement, placement = _f === void 0 ? 'topRight' : _f;
    if (txid) {
        //   <Link
        //     external
        //     to={'https://explorer.solana.com/tx/' + txid}
        //     style={{ color: '#0000ff' }}
        //   >
        //     View transaction {txid.slice(0, 8)}...{txid.slice(txid.length - 8)}
        //   </Link>
        description = React.createElement(React.Fragment, null);
    }
    notification[type]({
        message: React.createElement("span", { style: { color: 'black' } }, message),
        description: (React.createElement("span", { style: { color: 'black', opacity: 0.5 } }, description)),
        placement: placement,
        top: 100,
        style: {
            backgroundColor: 'white',
        },
    });
}
