// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../imgs/Welcome.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".intro-card {\n  display: flex;\n  flex: 1;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-position: center bottom;\n  background-size: cover;\n}\n.intro-card > div {\n  display: flex;\n  flex-direction: column;\n  padding: 36px;\n  font-family: 'TT Firs Neue';\n}\n.intro-card > div > div {\n  max-width: 455px;\n  font-size: 48px;\n  font-weight: 500;\n  line-height: 57.12px;\n  text-transform: uppercase;\n  color: white;\n}\n.intro-card > div > img {\n  width: 218px;\n  height: 36px;\n  margin-top: 16px;\n  object-fit: contain;\n}\n", "",{"version":3,"sources":["webpack://./components/IntroCard.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,OAAA;EACA,mDAAA;EACA,4BAAA;EACA,kCAAA;EACA,sBAAA;AACF;AAPA;EASI,aAAA;EACA,sBAAA;EACA,aAAA;EACA,2BAAA;AACJ;AAbA;EAeM,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,oBAAA;EACA,yBAAA;EACA,YAAA;AACN;AArBA;EAwBM,YAAA;EACA,YAAA;EACA,gBAAA;EACA,mBAAA;AAAN","sourcesContent":[".intro-card {\n  display: flex;\n  flex: 1;\n  background: url('../imgs/Welcome.png');\n  background-repeat: no-repeat;\n  background-position: center bottom;\n  background-size: cover;\n\n  >div {\n    display: flex;\n    flex-direction: column;\n    padding: 36px;\n    font-family: 'TT Firs Neue';\n\n    >div {\n      max-width: 455px;\n      font-size: 48px;\n      font-weight: 500;\n      line-height: 57.12px;\n      text-transform: uppercase;\n      color: white;\n    }\n\n    >img {\n      width: 218px;\n      height: 36px;\n      margin-top: 16px;\n      object-fit: contain;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
