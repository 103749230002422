var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useWallet, WalletProvider as BaseWalletProvider, } from '@solana/wallet-adapter-react';
import { getLedgerWallet, getMathWallet, getPhantomWallet, getSolflareWallet, getSolletWallet, getSolongWallet, } from '@solana/wallet-adapter-wallets';
import { Button } from 'antd';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState, } from 'react';
import { notify } from '../../utils/common';
import { MetaplexModal } from '../../components/MetaplexModal';
import { CollapsePanel } from '../../components/CollapsePanel';
export var WalletModalContext = createContext({});
export function useWalletModal() {
    return useContext(WalletModalContext);
}
export var WalletModal = function () {
    var _a = useWallet(), wallets = _a.wallets, select = _a.select;
    var _b = useWalletModal(), visible = _b.visible, setVisible = _b.setVisible;
    var close = useCallback(function () {
        setVisible(false);
    }, [setVisible]);
    var phatomWallet = useMemo(function () { return getPhantomWallet(); }, []);
    return (React.createElement(MetaplexModal, { title: "Connect Wallet", visible: visible, onCancel: close },
        React.createElement("span", { style: {
                color: 'rgba(255, 255, 255, 0.75)',
                fontSize: '14px',
                lineHeight: '14px',
                fontFamily: 'GraphikWeb',
                letterSpacing: '0.02em',
                marginBottom: 14,
            } }, "RECOMMENDED"),
        React.createElement(Button, { className: "phantom-button metaplex-button", onClick: function () {
                console.log(phatomWallet.name);
                select(phatomWallet.name);
                close();
            } },
            React.createElement("img", { src: phatomWallet === null || phatomWallet === void 0 ? void 0 : phatomWallet.icon, style: { width: '1.2rem' } }),
            "\u00A0Connect to Phantom"),
        (window.solflare != null) && (React.createElement(CollapsePanel, { id: "other-wallets", panelName: "Other Wallets" }, wallets.filter(function (item) { return item.name === "Solflare"; }).map(function (wallet, idx) {
            if (wallet.name === 'Phantom')
                return null;
            return (React.createElement(Button, { key: idx, className: "metaplex-button w100", style: {
                    marginBottom: 5,
                }, onClick: function () {
                    select(wallet.name);
                    close();
                } },
                "Connect to ",
                wallet.name));
        })))));
};
export var WalletModalProvider = function (_a) {
    var children = _a.children;
    var publicKey = useWallet().publicKey;
    var _b = __read(useState(!!publicKey), 2), connected = _b[0], setConnected = _b[1];
    var _c = __read(useState(false), 2), visible = _c[0], setVisible = _c[1];
    useEffect(function () {
        if (publicKey) {
            var base58 = publicKey.toBase58();
            var keyToDisplay = base58.length > 20
                ? "".concat(base58.substring(0, 7), ".....").concat(base58.substring(base58.length - 7, base58.length))
                : base58;
            notify({
                message: 'Wallet update',
                description: 'Connected to wallet ' + keyToDisplay,
            });
        }
    }, [publicKey]);
    useEffect(function () {
        if (!publicKey && connected) {
            notify({
                message: 'Wallet update',
                description: 'Disconnected from wallet',
            });
        }
        setConnected(!!publicKey);
    }, [publicKey, connected, setConnected]);
    return (React.createElement(WalletModalContext.Provider, { value: {
            visible: visible,
            setVisible: setVisible,
        } },
        children,
        React.createElement(WalletModal, null)));
};
export var WalletProvider = function (_a) {
    var children = _a.children;
    var wallets = useMemo(function () { return [
        getPhantomWallet(),
        getSolflareWallet(),
        getLedgerWallet(),
        getSolongWallet(),
        getMathWallet(),
        getSolletWallet(),
    ]; }, []);
    var onError = useCallback(function (error) {
        console.error(error);
        notify({
            message: 'Wallet error',
            description: error.message,
        });
    }, []);
    return (React.createElement(BaseWalletProvider, { wallets: wallets, onError: onError, autoConnect: true },
        React.createElement(WalletModalProvider, null, children)));
};
