import { PublicKey } from '@solana/web3.js';
var testReturn = process.env.APP_ENV === 'test';
export var CANDY_MACHINE_ID = new PublicKey('cndy3Z4yapfJBmL3ShUp5exZKqR3z33thTzeNMm2gRZ');
// export const GUMDROP_DISTRIBUTOR_ID = new PublicKey(
//   testReturn ? 'AB5ZCgrzpAjLZW4koBV2UdosAYMux1C8eR5jjy75XCc4' : 'AFjPkEKJPQLayM7wimd4bghPNy5HtnTPFEJ5zx5Lmm4h',
// );
export var GUMDROP_DISTRIBUTOR_ID = new PublicKey('gdrpGjVffourzkdDRrQmySw4aTHr8a3xmQzzxSwFD1a');
export var TOKEN_PROGRAM_ID = new PublicKey('TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA');
export var GUMDROP_TEMPORAL_SIGNER = new PublicKey('MSv9H2sMceAzccBganUXwGq3GXgqYAstmZAbFDZYbAV');
export var SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID = new PublicKey('ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL');
export var TOKEN_METADATA_PROGRAM_ID = new PublicKey('metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s');
