var config = {
    env: 'dev',
    distributor: 'FurtuENnxW3iBkrBecn79Ax76Y9kJTgfCq5dY2pyEEfa',
    // distributor: 'D4F4wWjM6DtWrcVsrSx112dpz7aTDEzi5VfZtUQfj2TS',
    tokenAccount: 'E1cgLAmuyCjhXtARuSsYGw3yj6k2fW1ao5yHRb5HG1sF',
    webUrl: 'https://h5.effectiveai.xyz'
    //生产正式环境模拟配置
    //   distributor: '3SGVb7RTAtZCJmYC3YfEnWWYeV7V3TaaaN8uX47wmEWr',
    //   tokenAccount: '26iAWZAXybtPfqZ4vj5fWt96ZoLvZYJx1RF2Dx2Y3BgL',
    //   webUrl:'https://h5.effectiveai.xyz'
};
export default config;
