import React from 'react';
import { Layout } from 'antd';
var Header = Layout.Header, Content = Layout.Content;
export var AppLayout = React.memo(function AppLayoutImpl(props) {
    return (React.createElement(React.Fragment, null,
        React.createElement(Layout, { id: 'main-layout' },
            React.createElement("span", { id: 'main-bg' }),
            React.createElement("span", { id: 'bg-gradient' }),
            React.createElement("span", { id: 'static-header-gradient' }),
            React.createElement("span", { id: 'static-end-gradient' }),
            React.createElement(Layout, { id: 'width-layout' },
                React.createElement(Content, { style: {
                        // padding: '1em 5em',
                        flex: 'unset',
                        minHeight: 'auto',
                    } },
                    React.createElement(Header, { className: "App-Bar" })),
                React.createElement(Content, { style: {
                        overflow: 'auto',
                        // padding: '0 2em',
                    } }, props.children)))));
});
