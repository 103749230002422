var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Connection, Keypair, Transaction, } from '@solana/web3.js';
import React, { useContext, useEffect, useState } from 'react';
import { TokenListProvider, ENV as ChainId, } from '@solana/spl-token-registry';
import { WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { envFor, sendSignedTransaction } from '../utils/transactions';
import { shortenAddress } from '../utils/common';
export var ENDPOINTS = [
    {
        name: 'mainnet-beta',
        url: 'https://ancient-skilled-wish.solana-mainnet.quiknode.pro/132804549b0f617506afb8b3fa05691e32fccc7a',
        chainId: ChainId.MainnetBeta,
    },
    {
        name: 'devnet',
        url: 'https://api.devnet.solana.com/',
        // url:'https://cosmological-warmhearted-gas.solana-devnet.quiknode.pro/17d2aa3d4c43e29bc092ce529266b1c168fd14dc/',
        chainId: ChainId.Devnet,
    },
    {
        name: 'testnet',
        url: 'https://api.testnet.solana.com/',
        chainId: ChainId.Testnet,
    }
];
var DEFAULT_ENDPOINT = process.env.APP_ENV === 'dev' ? ENDPOINTS[1] : (process.env.APP_ENV === 'test' ? ENDPOINTS[2] : ENDPOINTS[0]);
var ConnectionContext = React.createContext({
    connection: new Connection(DEFAULT_ENDPOINT.url, 'recent'),
    endpoint: DEFAULT_ENDPOINT,
    tokens: new Map(),
});
export function ConnectionProvider(_a) {
    // const searchParams = useQuerySearch();
    // const [networkStorage, setNetworkStorage] =
    //   useLocalStorageState<ENDPOINT_NAME>('network', DEFAULT_ENDPOINT.name);
    // const networkParam = searchParams.get('network');
    var children = _a.children;
    // let maybeEndpoint;
    // if (networkParam) {
    //   const endpointParam = ENDPOINTS.find(({ name }) => name === networkParam);
    //   if (endpointParam) {
    //     maybeEndpoint = endpointParam;
    //   }
    // }
    // if (networkStorage && !maybeEndpoint) {
    //   const endpointStorage = ENDPOINTS.find(
    //     ({ name }) => name === networkStorage,
    //   );
    //   if (endpointStorage) {
    //     maybeEndpoint = endpointStorage;
    //   }
    // }
    var endpoint = DEFAULT_ENDPOINT;
    var connection = React.useRef(new Connection(endpoint.url)).current;
    var _b = __read(useState(new Map()), 2), tokens = _b[0], setTokens = _b[1];
    useEffect(function () {
        function fetchTokens() {
            return new TokenListProvider().resolve().then(function (container) {
                var list = container
                    .excludeByTag('nft')
                    .filterByChainId(endpoint.chainId)
                    .getList();
                var map = new Map(list.map(function (item) { return [item.address, item]; }));
                setTokens(map);
            });
        }
        fetchTokens();
    }, []);
    // useEffect(() => {
    //   function updateNetworkInLocalStorageIfNeeded() {
    //     if (networkStorage !== endpoint.name) {
    //       setNetworkStorage(endpoint.name);
    //     }
    //   }
    //   updateNetworkInLocalStorageIfNeeded();
    // }, []);
    // solana/web3.js closes its websocket connection when the subscription list
    // is empty after opening for the first time, preventing subsequent
    // subscriptions from receiving responses.
    // This is a hack to prevent the list from ever being empty.
    useEffect(function () {
        var id = connection.onAccountChange(Keypair.generate().publicKey, function () { });
        return function () {
            connection.removeAccountChangeListener(id);
        };
    }, []);
    useEffect(function () {
        var id = connection.onSlotChange(function () { return null; });
        return function () {
            connection.removeSlotChangeListener(id);
        };
    }, []);
    var contextValue = React.useMemo(function () {
        return {
            endpoint: endpoint,
            connection: connection,
            tokens: tokens,
        };
    }, [tokens]);
    return (React.createElement(ConnectionContext.Provider, { value: contextValue }, children));
}
export function useConnection() {
    var context = useContext(ConnectionContext);
    if (!context) {
        throw new Error('ConnectionContext must be used with a ConnectionProvider');
    }
    return context.connection;
}
export function useConnectionConfig() {
    var context = useContext(ConnectionContext);
    if (!context) {
        throw new Error('ConnectionContext must be used with a ConnectionProvider');
    }
    return {
        endpoint: context.endpoint,
        tokens: context.tokens,
    };
}
export var explorerLinkCForAddress = function (key, connection, shorten) {
    if (shorten === void 0) { shorten = true; }
    return (React.createElement("a", { href: "https://explorer.solana.com/address/".concat(key, "?cluster=").concat(envFor(connection)), target: "_blank", rel: "noreferrer", title: key, style: {
            fontFamily: 'Monospace',
            color: '#7448A3',
        } }, shorten ? shortenAddress(key) : key));
};
export var getErrorForTransaction = function (connection, txid) { return __awaiter(void 0, void 0, void 0, function () {
    var tx, errors;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: 
            // wait for all confirmation before geting transaction
            return [4 /*yield*/, connection.confirmTransaction(txid, 'max')];
            case 1:
                // wait for all confirmation before geting transaction
                _a.sent();
                return [4 /*yield*/, connection.getParsedConfirmedTransaction(txid)];
            case 2:
                tx = _a.sent();
                errors = [];
                if ((tx === null || tx === void 0 ? void 0 : tx.meta) && tx.meta.logMessages) {
                    tx.meta.logMessages.forEach(function (log) {
                        var regex = /Error: (.*)/gm;
                        var m;
                        while ((m = regex.exec(log)) !== null) {
                            // This is necessary to avoid infinite loops with zero-width matches
                            if (m.index === regex.lastIndex) {
                                regex.lastIndex++;
                            }
                            if (m.length > 1) {
                                errors.push(m[1]);
                            }
                        }
                    });
                }
                return [2 /*return*/, errors];
        }
    });
}); };
export var SequenceType;
(function (SequenceType) {
    SequenceType[SequenceType["Sequential"] = 0] = "Sequential";
    SequenceType[SequenceType["Parallel"] = 1] = "Parallel";
    SequenceType[SequenceType["StopOnFailure"] = 2] = "StopOnFailure";
})(SequenceType || (SequenceType = {}));
export var sendTransactionWithRetry = function (connection, wallet, instructions, signers, commitment, includesFeePayer, block, beforeSend) {
    if (commitment === void 0) { commitment = 'singleGossip'; }
    if (includesFeePayer === void 0) { includesFeePayer = false; }
    return __awaiter(void 0, void 0, void 0, function () {
        var transaction, _a, _b, _c, _d, txid, slot, error_1;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    if (!wallet.publicKey)
                        throw new WalletNotConnectedError();
                    transaction = new Transaction();
                    instructions.forEach(function (instruction) { return transaction.add(instruction); });
                    _a = transaction;
                    _b = block;
                    if (_b) return [3 /*break*/, 2];
                    return [4 /*yield*/, connection.getRecentBlockhash(commitment)];
                case 1:
                    _b = (_e.sent());
                    _e.label = 2;
                case 2:
                    _a.recentBlockhash = (_b).blockhash;
                    if (includesFeePayer) {
                        transaction.setSigners.apply(transaction, __spreadArray([], __read(signers.map(function (s) { return s.publicKey; })), false));
                    }
                    else {
                        transaction.setSigners.apply(transaction, __spreadArray([
                            // fee payed by the wallet owner
                            wallet.publicKey], __read(signers.map(function (s) { return s.publicKey; })), false));
                    }
                    if (signers.length > 0) {
                        transaction.partialSign.apply(transaction, __spreadArray([], __read(signers), false));
                    }
                    if (!!includesFeePayer) return [3 /*break*/, 6];
                    _e.label = 3;
                case 3:
                    _e.trys.push([3, 5, , 6]);
                    return [4 /*yield*/, wallet.signTransaction(transaction)];
                case 4:
                    transaction = _e.sent();
                    return [3 /*break*/, 6];
                case 5:
                    _c = _e.sent();
                    return [2 /*return*/, 'Failed to sign transaction'];
                case 6:
                    if (beforeSend) {
                        beforeSend();
                    }
                    console.log('About to send');
                    _e.label = 7;
                case 7:
                    _e.trys.push([7, 9, , 10]);
                    return [4 /*yield*/, sendSignedTransaction({
                            connection: connection,
                            signedTransaction: transaction,
                        })];
                case 8:
                    _d = _e.sent(), txid = _d.txid, slot = _d.slot;
                    return [2 /*return*/, { txid: txid, slot: slot }];
                case 9:
                    error_1 = _e.sent();
                    console.error(error_1);
                    return [2 /*return*/, 'See console logs'];
                case 10: return [2 /*return*/];
            }
        });
    });
};
