var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Dropdown, Menu } from 'antd';
import React, { useCallback } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { useWalletModal } from '../../contexts/WalletContext';
import { Button } from '../Button';
export var ConnectButton = function (props) {
    var children = props.children, disabled = props.disabled, allowWalletChange = props.allowWalletChange, className = props.className, rest = __rest(props, ["children", "disabled", "allowWalletChange", "className"]);
    var _a = useWallet(), wallet = _a.wallet, connect = _a.connect, connected = _a.connected;
    var setVisible = useWalletModal().setVisible;
    var open = useCallback(function () { return setVisible(true); }, [setVisible]);
    var handleClick = useCallback(function () { return (wallet ? connect().catch(function () { }) : open()); }, [wallet, connect, open]);
    // only show if wallet selected or user connected
    if (!wallet || !allowWalletChange) {
        return (React.createElement(Button, { className: className || 'connector', onClick: handleClick, disabled: connected && disabled, style: { background: 'linear-gradient(to right, #2FD3C8, #8F78FF) !important' } }, children));
    }
    return (React.createElement(Dropdown.Button, { className: className || (connected ? 'connector' : ''), onClick: handleClick, disabled: connected && disabled, overlay: React.createElement(Menu, { className: 'black-dropdown' },
            React.createElement(Menu.Item, { onClick: open }, "Change Wallet")) }, "Connect"));
};
