import React from 'react';
import AppLogo from '../imgs/App-logo.png';
import './IntroCard.less';
function IntroCard() {
    return React.createElement("div", { className: 'intro-card' },
        React.createElement("div", null,
            React.createElement("div", null,
                "Your contributions  MATTER to AI.",
                React.createElement("br", null),
                "Get recognized."),
            React.createElement("img", { className: 'h-9 object-contain mt-4', src: AppLogo })));
}
export default IntroCard;
