var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { notification } from 'antd';
export var useLocalStorage = function () {
    var isBrowser = (function () { return typeof window !== 'undefined'; })();
    var getItem = function (key) {
        return isBrowser ? window.localStorage[key] : '';
    };
    var setItem = function (key, value) {
        if (isBrowser) {
            window.localStorage.setItem(key, value);
            return true;
        }
        return false;
    };
    var removeItem = function (key) {
        window.localStorage.removeItem(key);
    };
    return {
        getItem: getItem,
        setItem: setItem,
        removeItem: removeItem,
    };
};
export function useLocalStorageState(key, defaultState) {
    var localStorage = useLocalStorage();
    var _a = __read(React.useState(function () {
        console.debug('Querying local storage', key);
        var storedState = localStorage.getItem(key);
        console.debug('Retrieved local storage', storedState);
        if (storedState) {
            return JSON.parse(storedState);
        }
        return defaultState;
    }), 2), state = _a[0], setState = _a[1];
    var setLocalStorageState = React.useCallback(function (newState) {
        var changed = state !== newState;
        if (!changed) {
            return;
        }
        setState(newState);
        if (newState === null) {
            localStorage.removeItem(key);
        }
        else {
            try {
                localStorage.setItem(key, JSON.stringify(newState));
            }
            catch (_a) {
                // ignore
            }
        }
    }, [state, key]);
    return [state, setLocalStorageState];
}
export function notify(_a) {
    var _b = _a.message, message = _b === void 0 ? '' : _b, _c = _a.description, description = _c === void 0 ? undefined : _c, _d = _a.txid, txid = _d === void 0 ? '' : _d, _e = _a.type, type = _e === void 0 ? 'info' : _e, _f = _a.placement, placement = _f === void 0 ? 'topRight' : _f;
    if (txid) {
        //   <Link
        //     external
        //     to={'https://explorer.solana.com/tx/' + txid}
        //     style={{ color: '#0000ff' }}
        //   >
        //     View transaction {txid.slice(0, 8)}...{txid.slice(txid.length - 8)}
        //   </Link>
        description = React.createElement(React.Fragment, null);
    }
    notification[type]({
        message: React.createElement("span", { style: { color: 'black' } }, message),
        description: (React.createElement("span", { style: { color: 'black', opacity: 0.5 } }, description)),
        placement: placement,
        top: 100,
        style: {
            backgroundColor: 'white',
        },
    });
}
// shorten the checksummed version of the input address to have 4 characters at start and end
export function shortenAddress(address, chars) {
    if (chars === void 0) { chars = 4; }
    return "".concat(address.slice(0, chars), "...").concat(address.slice(-chars));
}
export var formatUSD = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});
var numberFormater = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});
export var formatNumber = {
    format: function (val) {
        if (!val) {
            return '--';
        }
        return numberFormater.format(val);
    },
};
export function sleep(ms) {
    return new Promise(function (resolve) { return setTimeout(resolve, ms); });
}
export function chunks(array, size) {
    return Array.apply(0, new Array(Math.ceil(array.length / size))).map(function (_, index) { return array.slice(index * size, (index + 1) * size); });
}
